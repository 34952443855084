const columns = Object.freeze([
  {
    key: 'name',
    label: 'Товар',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'price',
    label: 'Затраты',
    sortable: true,
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'type',
    label: 'Тип расхода',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'date',
    label: 'Дата покупки',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'manager',
    label: 'Менеджер',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'photo',
    label: 'Фото чека',
    thClass: 'w-15 text-center align-middle',
    tdClass: 'text-center align-middle',
  },
  {
    key: 'actions',
    label: '',
    thClass: 'text-center align-middle',
    tdClass: 'text-center align-middle',
  },
])

const typeOfExpense = Object.freeze([
  { text: 'Переменные', value: 1 },
  { text: 'Операционные', value: 2 },
])

const modal = {
  delete: {
    title: 'Вы действительно хотите удалить?',
    content: 'Вы действительно хотите удалить',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото чека',
    visible: false,
    okTitle: 'Закрыть',
  },
  edit: {
    title: 'Изменить',
    visible: false,
    okTitle: 'Изменить',
  },
  create: {
    title: 'Создать Расход',
    visible: false,
    okTitle: 'Создать',
  },
}

const ExpenseType = Object.freeze({
  1: 'Переменные',
  2: 'Операционные',
})

export { columns, modal, typeOfExpense, ExpenseType }
