var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.maxDate && _vm.minDate)?[_c('table-header',_vm._b({on:{"create":_vm.onCreate,"update":_vm.onUpdateFilter,"reset":_vm.onResetFilter},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('b-col',{staticClass:"mb-1",attrs:{"lg":"2","md":"6","sm":"12"}},[_c('common-select',_vm._b({on:{"change":_vm.onUpdateFilter}},'common-select',{
            name: 'type',
            value: _vm.type,
            options: _vm.typeOfExpense,
            placeholder: 'Типы затрат',
          },false))],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"2","md":"6","sm":"12"}},[_c('common-select',_vm._b({on:{"change":_vm.onUpdateFilter}},'common-select',{
            name: 'manager_id',
            value: _vm.manager_id,
            options: _vm.staffCombined,
            placeholder: 'Менеджер',
            valueField: 'id',
            textField: 'full_name'
          },false))],1)]},proxy:true}],null,false,2905117211)},'table-header',{
      createTitle: !_vm.hasPermission
      ? 'Добавить расход' : '',
      currentPage: _vm.page,
      minDate: _vm.minDate,
      maxDate: _vm.maxDate,
    },false))]:_vm._e(),_c('table-body',_vm._b({on:{"update:page-size":_vm.onPerPageChange,"update:page":_vm.onPageChange}},'table-body',{
      currentPage: _vm.page,
      pageCount: _vm.pageCount,
      itemCount: _vm.itemCount,
      pageSize: _vm.page_size,
    },false),[_c('b-table',_vm._b({staticClass:"position-relative",on:{"sort-changed":_vm.onSort},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('span',[_vm._v("Загрузка...")])],1)]},proxy:true},{key:"cell(photo)",fn:function(ref){
    var item = ref.item;
return [(item.photo)?[_c('div',{on:{"click":function($event){return _vm.onImage(Object.assign({}, item))}}},[_c('b-img',_vm._b({on:{"click":function($event){_vm.activeImgUrl = "" + _vm.url + (item.photo)}}},'b-img',{
                src: ("" + _vm.url + (item.photo)),
                alt: '',
                width: '100px',
              },false))],1)]:[_c('b-badge',_vm._b({},'b-badge',{
            variant: 'danger'
          },false),[_vm._v(" Нет чека ")])]]}},{key:"cell(type)",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.expenseType[item.type])+" ")]}},{key:"cell(date)",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$dayjs(item.date * 1000).format('DD.MM.YYYY'))+" ")])]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('table-buttons',_vm._b({on:{"edit":function($event){return _vm.onEdit(Object.assign({}, item))},"delete":function($event){return _vm.onDelete(Object.assign({}, item))}}},'table-buttons',{
            disabled: _vm.hasPermission,
          },false))]}}])},'b-table',{
        items: _vm.bills,
        fields: _vm.fields,
        striped: _vm.striped,
        hover: _vm.hover,
        responsive: _vm.responsive,
        showEmpty: true,
        emptyText: _vm.emptyText,
        perPage: _vm.page_size,
        busy: _vm.busy,
      },false))],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
return [_c('common-dialog',_vm._b({on:{"confirm":_vm.onConfirm}},'common-dialog',{
      title: _vm.modal.delete.visible
        ? _vm.modal.delete.title
        : _vm.modal.image.visible
          ? _vm.modal.image.title
          : _vm.modal.create.visible
           ? _vm.modal.create.title
           : _vm.modal.edit.title,
      value: _vm.modal.delete.visible ||
        _vm.modal.image.visible ||
        _vm.modal.edit.visible ||
        _vm.modal.create.visible,
      okOnly: _vm.modal.image.visible,
      okTitle: _vm.modal.delete.visible
        ? _vm.modal.delete.okTitle
        : _vm.modal.image.visible
          ? _vm.modal.image.okTitle
          : _vm.modal.edit.visible
          ? _vm.modal.edit.okTitle: _vm.modal.create.okTitle,
      hideHeader: _vm.modal.image.visible,
      hideFooter: _vm.modal.image.visible,
      size: _vm.modal.image.visible ? 'lg' : '',
      modalClass: _vm.modal.image.visible ? 'app-modal-img' : '',
      okDisabled: invalid
    },false),[(_vm.modal.delete.visible)?[_c('p',[_vm._v(" "+_vm._s(((_vm.modal.delete.content) + " " + _vm.name + "?"))+" ")])]:_vm._e(),(_vm.modal.image.visible)?[_c('div',{staticClass:"d-flex flex-wrap justify-content-center"},[_c('b-img',_vm._b({},'b-img',{
          src: _vm.activeImgUrl,
          },false))],1)]:_vm._e(),(_vm.modal.edit.visible || _vm.modal.create.visible)?[_c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return _c('b-form-group',_vm._b({},'b-form-group',{
                label:  'Товар'
              },false),[_c('b-input',_vm._b({model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}},'b-input',{
                state: errors[0] ? false : (valid ? true : null)
              },false))],1)}}],null,true)},'validation-provider',{
            rules: {
              required: true,
            }
          },false)),_c('validation-provider',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var valid = ref.valid;
          var errors = ref.errors;
return _c('b-form-group',_vm._b({},'b-form-group',{
                label:  'Сумма'
              },false),[_c('b-input',_vm._b({model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.price"}},'b-input',{
                state: errors[0] ? false : (valid ? true : null)
              },false))],1)}}],null,true)},'validation-provider',{
            rules: {
              required: true,
            }
          },false)),_c('validation-provider',_vm._b({},'validation-provider',{
            rules: {
              required: true,
            }
          },false),[_c('b-form-group',_vm._b({},'b-form-group',{
                label: 'Дата'
              },false),[_c('b-form-datepicker',_vm._b({model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}},'b-form-datepicker',{
                startWeekday: 1,
                placeholder: 'Выберите дату',
                locale: 'ru',
                labelHelp: '',
              },false))],1)],1),_c('b-form-group',_vm._b({},'b-form-group',{
                label: 'Тип'
              },false),[_c('b-form-select',{staticClass:"mb-1",attrs:{"options":_vm.typeOfExpense},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('label',{attrs:{"for":"item-date"}},[_vm._v("Фото чека")]),_c('b-form-file',{staticClass:"mb-1",attrs:{"state":Boolean(_vm.form.file),"placeholder":"Выберите или перетащите файл..."},on:{"input":_vm.onAddFile},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}}),(!_vm.loading && _vm.form.photo_url)?[_c('b-img',{attrs:{"src":_vm.setModalImg(),"fluid":""}})]:_vm._e(),(_vm.loading)?[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"spinner-border text-secondary mauto",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]:_vm._e()]:_vm._e()],2)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }