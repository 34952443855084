<template>
  <section>
    <template v-if="maxDate && minDate">
      <table-header
        v-bind="{
        createTitle: !hasPermission
        ? 'Добавить расход' : '',
        currentPage: page,
        minDate,
        maxDate,
      }"
        @create='onCreate'
        @update="onUpdateFilter"
        @reset="onResetFilter"
      >

        <template #filters>
          <b-col
            class="mb-1"
            lg="2"
            md="6"
            sm=12
          >
            <common-select
              v-bind="{
              name: 'type',
              value: type,
              options: typeOfExpense,
              placeholder: 'Типы затрат',
            }"
              @change="onUpdateFilter"
            />
          </b-col>

          <b-col
            class="mb-1"
            lg="2"
            md="6"
            sm=12
          >
            <common-select
              v-bind="{
              name: 'manager_id',
              value: manager_id,
              options: staffCombined,
              placeholder: 'Менеджер',
              valueField: 'id',
              textField: 'full_name'
            }"
              @change="onUpdateFilter"
            />
          </b-col>
        </template>
      </table-header>
    </template>
    <table-body
      v-bind="{
        currentPage: page,
        pageCount,
        itemCount,
        pageSize: page_size,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: bills,
          fields,
          striped,
          hover,
          responsive,
          showEmpty: true,
          emptyText,
          perPage: page_size,
          busy: busy,
        }"
        @sort-changed="onSort"
      >
        <template #table-busy>
          <div class="text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
        <template #cell(photo)="{ item }">
          <template v-if="item.photo">
            <div @click="onImage({ ...item })">
              <b-img
                v-bind="{
                  src: `${url}${item.photo}`,
                  alt: '',
                  width: '100px',
                }"
                @click="activeImgUrl = `${url}${item.photo}`"
              ></b-img>
            </div>
          </template>
          <template v-else>
            <b-badge v-bind="{
              variant: 'danger'
            }"> Нет чека </b-badge>
          </template>
        </template>

        <template #cell(type)="{ item }">
          {{ expenseType[item.type] }}
        </template>

        <template #cell(date)="{ item }">
          <span> {{ $dayjs(item.date * 1000).format('DD.MM.YYYY') }} </span>
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission,
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons>
        </template>
      </b-table>
    </table-body>

    <validation-observer v-slot="{ invalid }">
      <common-dialog
        v-bind="{
        title: modal.delete.visible
          ? modal.delete.title
          : modal.image.visible
            ? modal.image.title
            : modal.create.visible
             ? modal.create.title
             : modal.edit.title,
        value: modal.delete.visible ||
          modal.image.visible ||
          modal.edit.visible ||
          modal.create.visible,
        okOnly: modal.image.visible,
        okTitle: modal.delete.visible
          ? modal.delete.okTitle
          : modal.image.visible
            ? modal.image.okTitle
            : modal.edit.visible
            ? modal.edit.okTitle: modal.create.okTitle,
        hideHeader: modal.image.visible,
        hideFooter: modal.image.visible,
        size: modal.image.visible ? 'lg' : '',
        modalClass: modal.image.visible ? 'app-modal-img' : '',
        okDisabled: invalid
      }"
        @confirm="onConfirm"
      >
        <template v-if="modal.delete.visible">
          <p> {{ `${modal.delete.content} ${name}?`}} </p>
        </template>
        <template v-if="modal.image.visible">
          <div class="d-flex flex-wrap justify-content-center">
            <b-img v-bind="{
            src: activeImgUrl,
            }"></b-img>
          </div>
        </template>
        <template v-if="modal.edit.visible || modal.create.visible">

          <validation-provider v-bind="{
              rules: {
                required: true,
              }
            }">
            <b-form-group
              slot-scope="{ valid, errors }"
              v-bind="{
                  label:  'Товар'
                }"
            >
              <b-input
                v-model.trim="form.name"
                v-bind="{
                  state: errors[0] ? false : (valid ? true : null)
                }"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider v-bind="{
              rules: {
                required: true,
              }
            }">
            <b-form-group
              slot-scope="{ valid, errors }"
              v-bind="{
                  label:  'Сумма'
                }"
            >
              <b-input
                v-model.trim="form.price"
                v-bind="{
                  state: errors[0] ? false : (valid ? true : null)
                }"
              />
            </b-form-group>
          </validation-provider>

          <validation-provider v-bind="{
              rules: {
                required: true,
              }
            }">
            <b-form-group v-bind="{
                  label: 'Дата'
                }">
              <b-form-datepicker
                v-model="form.date"
                v-bind="{
                  startWeekday: 1,
                  placeholder: 'Выберите дату',
                  locale: 'ru',
                  labelHelp: '',
                }"
              ></b-form-datepicker>
            </b-form-group>
          </validation-provider>

          <b-form-group v-bind="{
                  label: 'Тип'
                }">
            <b-form-select
              v-model="form.type"
              :options="typeOfExpense"
              class="mb-1"
            ></b-form-select>
          </b-form-group>

          <label for="item-date">Фото чека</label>
          <b-form-file
            v-model="form.file"
            :state="Boolean(form.file)"
            @input="onAddFile"
            class="mb-1"
            placeholder="Выберите или перетащите файл..."
          ></b-form-file>
          <template v-if="!loading && form.photo_url">
            <b-img
              :src="setModalImg()"
              fluid
            ></b-img>
          </template>
          <template v-if="loading">
            <div class="d-flex justify-content-center">
              <div
                class="spinner-border text-secondary mauto"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </template>
        </template>
      </common-dialog>
    </validation-observer>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { CostNameSpace, CostActionTypes } from '@/store/modules/cost/types'
import { FileNameSpace, FileActionTypes } from '@/store/modules/file/types'
import { StaffNameSpace, StaffActionTypes } from '@/store/modules/staff/types'
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { RootMutationTypes } from '@/store/types'
import { throttle } from 'lodash'
import { duration, BASE_URL } from '@/config'
import { BTable } from 'bootstrap-vue'
import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'
import { columns, modal, typeOfExpense, ExpenseType } from '@/config/cost'

export default {
  name: 'CalendarOfExpenses',

  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    CommonSelect: () => import('@/components/common/common-select'),
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },
  data() {
    return {
      id: '',
      name: '',
      price: null,
      date: null,
      type: null,
      manager_id: null,
      search: '',
      date_from: '',
      date_to: '',
      city_id: this.active_city,
      form: {
        date: new Date(),
        photo_url: '',
        manager_user_id: '',
        file: [],
        type: null,
      },
      status: '',
      page_size: 10,
      page: 1,
      modal,
      activeImgUrl: '',
      sort_by: {
        sortBy: 'price',
        sortDesc: 'asc',
      },
      staffCollection: [],
      // TODO update on back req for get time
      busy: true,
      time: true,
      pending: true,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    url: {
      type: String,
      default() {
        return BASE_URL
      },
    },
  },
  computed: {
    ...mapState({
      loading: 'loaded',
    }),
    ...mapState(StaffNameSpace, {
      staff: 'staffCollection',
    }),
    ...mapState(CostNameSpace, {
      bills: 'bills',
      cost: 'cost',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
      minDate: 'date_from',
      maxDate: 'date_to',
    }),
    ...mapState(FileNameSpace, {
      files: 'files',
      file: 'file',
    }),
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),
    fields() {
      return columns
    },
    typeOfExpense() {
      return typeOfExpense
    },
    expenseType() {
      return ExpenseType
    },
    staffCombined() {
      return this.staffCollection
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    // TODO to delete after back update
    this.pending = true
    await this.loadStaffCollection()
    await this.loadCollection()
    this.time = false
    await this.loadCollection()

    this.pending = false
  },
  methods: {
    ...mapMutations(RootMutationTypes, {
      [RootMutationTypes.SetLoadedState]: RootMutationTypes.SetLoadedState,
    }),
    ...mapActions(CostNameSpace, {
      [CostActionTypes.LoadCostCollection]: CostActionTypes.LoadCostCollection,
      [CostActionTypes.LoadCost]: CostActionTypes.LoadCost,
      [CostActionTypes.CreateCost]: CostActionTypes.CreateCost,
      [CostActionTypes.UpdateCost]: CostActionTypes.UpdateCost,
      [CostActionTypes.DeleteCost]: CostActionTypes.DeleteCost,
    }),

    ...mapActions(FileNameSpace, {
      [FileActionTypes.LoadFile]: FileActionTypes.LoadFile,
    }),

    ...mapActions(StaffNameSpace, {
      [StaffActionTypes.LoadStaffCollection]: StaffActionTypes.LoadStaffCollection,
    }),

    loadCollection: throttle(async function() {
      this.busy = true
      await this[CostActionTypes.LoadCostCollection]({ ...this.$data })
      if (this.bills) this.busy = false
    }, duration),

    loadStaffCollection: throttle(async function() {
      this.staffCollection = []
      await this[StaffActionTypes.LoadStaffCollection]({ page: 1, per_page: 50, role: 'manager', city_id: this.active_city })
      this.staffCollection.push(...this.staff)
      await this[StaffActionTypes.LoadStaffCollection]({ page: 1, per_page: 50, role: 'admin', city_id: this.active_city })
      this.staffCollection.push(...this.staff)
    }, duration),

    load: throttle(async function({ id }) {
      await this[CostActionTypes.LoadCost]({ id })
    }, duration),

    onCreate() {
      for (const key in this.form) {
        if (this.form[key]) {
                    key === 'date' ? (this.form[key] = new Date()) : (this.form[key] = '')
        }
      }

      this.form.type = 1
      this.form.file = []

      this.modal.create.visible = true
    },

    async onEdit({ id }) {
      await this[CostActionTypes.LoadCost]({ id })
      Object.assign(this.form, this.cost)
      this.form.file = []
      this.form.date = new Date(this.cost.date * 1000)
      this.modal.edit.visible = true
    },

    onDelete({ id, name }) {
      this.id = id
      this.name = name

      this.modal.delete.visible = true
    },

    onImage({ photo }) {
      this.photo = photo

      this.modal.image.visible = true
    },

    async onAddFile() {
      this[RootMutationTypes.SetLoadedState]({ loaded: true })
      await this[FileActionTypes.LoadFile]({ ...this.form })
      this[RootMutationTypes.SetLoadedState]({ loaded: false })
      this.form.photo_url = this.file.path
      this.form.img = this.file.url
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.edit.visible) {
            this.form.manager_user_id = this.userData.id
            const date = this.$dayjs(this.form.date).unix()
            await this[CostActionTypes.UpdateCost]({ ...this.form, date: date, city_id: this.active_city })
            await this.loadCollection()
          }

          if (this.modal.create.visible) {
            this.form.manager_user_id = this.userData.id
            const date = this.$dayjs(this.form.date).unix()
            await this[CostActionTypes.CreateCost]({ ...this.form, date: date, city_id: this.active_city })
            await this.loadCollection()
          }

          if (this.modal.delete.visible) {
            await this[CostActionTypes.DeleteCost]({ ...this.$data })

            if (this.bills.length === 1 && this.page !== 1) this.page -= 1

            await this.loadCollection()
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection()
    },

    async onResetFilter(context) {
      Object.assign(this.$data, context)
      this.type = null
      this.manager_id = null
      await this.loadCollection()
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)
      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)
      await this.loadCollection()
    },

    setModalImg() {
      if (this.modal.create.visible) {
        return this.form.photo_url ? this.url + this.form.photo_url : ''
      }
      if (this.modal.edit.visible) {
        return this.form.photo_url ? this.url + this.form.photo_url : this.url + this.cost.photo_url
      }
    },

    async onSort({ sortBy, sortDesc }) {
      if (sortBy) {
        if (sortBy !== this.sort_by.sortBy) {
          this.sort_by.sortBy = sortBy
        }
        this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'
      }

      await this.loadCollection()
    },
  },
  watch: {
    async active_city(city_id) {
      this.city_id = city_id
      if (this.page > 1) this.page = 1
      Object.assign(this.$data, { date_from: '', date_to: '' })
      await this.loadCollection()
      await this.loadStaffCollection()
    },
  },
}
</script>

<style scoped>
</style>
